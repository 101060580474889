/* form.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--main-font-family);
}

body {
    width: 100%;
    height: 100vh;
}

.form-popup {
    display: flex; /* Altere a exibição para flex */
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 720px;
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 20px #8298a97b;
    opacity: 100;

    backdrop-filter: blur(8px) saturate(159%);
    -webkit-backdrop-filter: blur(10px) saturate(159%);
    background-color: rgba(255, 255, 255, 0.16);
    border-radius: 12px;
    border: 1px solid rgba(173, 186, 205, 0.282);
}

.form-popup .form-box {
    display: flex;
}

.form-box h2 {
    justify-content: baseline;
    text-align: center;
    margin-top: 5px;
    color: var(--main-fonts-color);
}

.form-box .form-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 78vh;
    padding: 10px 20px;
    align-items: center;
}

form .input-field {
    height: 45px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
    position: relative;
    font-family: var(--third-font-family);
}

form .input-field-terms{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 100%;
    position: relative;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

form .input-field-terms button{
    margin: 0;
    padding: 0;
}

form .input-field-terms label{
    display: flex;
    gap: 10px;
    font-size:medium;
}

form .input-field-redefinir {
    height: 45px;
    width: 100%;
    position: relative;
}


form .input-field input,
form .input-field-redefinir input {
    width: 100%;
    height: 100%;
    outline: none;
    padding: 0 15px;
    font-size: 0.95rem;
    outline-style: none;
    border-style: none;
    border-radius: 3px;
    background-color: #1e273725;
    color: var(--main-fonts-color);
}

.input-field label {
    position: absolute;
    top: 8px; /* Posição inicial da label */
    left: 15px;
    color: var(--main-fonts-color);
    pointer-events: none; 
    transition: 0.2s ease; /* Transição suave */
    font-size: 1.2rem; /* Tamanho da fonte da label */
    margin-bottom: 5px; /* Adiciona um espaçamento maior para a label dos termos */
}

.input-field input:focus {
    border: 2px solid;
    border-color: var(--main-blue-border-shadow)
}

/* Seletor modificado para inputs preenchidos ou válidos */
.input-field input:is(:not(:placeholder-shown), :valid) { 
    padding: 16px 15px 0;
    border-color: var(--main-blue-border-shadow)    
}

.input-field input:is(:focus, :valid) {
    padding: 16px 15px 0;
    border-color: var(--main-blue-border-shadow)
    
}

.input-field input:is(:focus, :valid) ~ label {
    font-size: 0.95rem; /* Tamanho da fonte da label em foco */
    top: 2px; /* Posição da label em foco */
    color: var(--second-fonts-color);
}

.form-box a {
    color: var(--blue-fonts-color);
    text-decoration: none;
}

.form-box a:hover {
    text-decoration: underline;
}

.form-box :where(.forgot-pass, .policy-text) {
    display: inline-flex;
    margin-top: 14px;
    font-size: 0.95rem;
    color: var(--main-fonts-color);
}

.input-fieldError {
    color: #fff;
    font-size: 0.95rem;
    margin-top: 5px;
}

form button {
    width: 100%;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 14px 0;
    border-radius: 3px;
    margin: 0;
    color: #fff;
    cursor: pointer;
    background-color: #1f9398;
    transition: 0.2s ease;
}

form button:hover {
    background-color: #26b7be;
}

.form-box .bottom-link {
    text-align: center;
    color: var(--main-fonts-color);
    font-size: 0.95rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-box .bottom-link a {
    color: var(--blue-fonts-color);
    text-decoration: none;
    margin-left: 0.5rem; /* Espaçamento entre o texto e o link */
    font-weight: bold; /* Estilo do link */
    transition: color 0.2s ease; /* Transição suave da cor */
  }
  
  .form-box .bottom-link a:hover {
    text-decoration: underline;
    color: var(--blue-hover-fonts-color);
  }
  
  .form-box .forgot-password {
    text-align: center;
    color: var(--main-fonts-color);
    font-size: 0.95rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-box .forgot-password-message {
    text-align: center;
    color: var(--red-fonts-color);
    font-size: 0.95rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-box .forgot-password a {
    color: var(--red-fonts-color);
    text-decoration: none;
    margin-left: 0.5rem; /* Espaçamento entre o texto e o link */
    font-weight: bold; /* Estilo do link */
    transition: color 0.2s ease; /* Transição suave da cor */
  }
  
  .form-box .forgot-password a:hover {
    text-decoration: underline;
    color: var(--red-hover-fonts-color);
  }

.input-field input[type="checkbox"] {
    /* ... (estilos para o checkbox) */
    margin-right: 5px; /* Adiciona um espaçamento à direita do checkbox */
}

.input-field input[type="checkbox"] + label {
    /* ... (estilos para a label do checkbox) */
    /* Alinha a label com o checkbox */
    display: inline-block; /* Define a exibição como bloco inline */
}

@media (max-width: 960px) {
    
    .form-popup {
        display: flex; /* Altere a exibição para flex */
        justify-content: center;
        position: fixed;
        width: 95%;
        top: 53%;
        left: 50%;
        max-width: 720px;
        background-color: transparent;
        border: 2px solid #2ffbfe;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        box-shadow: 5px 5px 80px #006395;
        opacity: 100;
    }
    


   
}