/* Home.css */

.home-container { 
  top: 162px;
  left: 77px;
  width: 100vw;
  min-width: 1235px;
  max-width: 1230px;
  min-height: 520px;
  max-height: 520px;
  overflow-y: hidden; 
  border-radius: 3px; 
  padding: 20px;
  margin-top: 38px; 
  margin-left: 22px;
  padding-top: 10px; 
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.project-header { 
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  top: 70px; 
  width: 100%;
  z-index: 10;
  padding: 0px;
}

.btn-group-header {
  display: flex;
  justify-content: center; 
}

.welcome-title { 
  display: flex;
  align-items: baseline;
  gap: 10px;
  text-align: center;
  margin-bottom: 0px;
  white-space: nowrap;
}

.welcome-title h1 {
  color: var(--main-fonts-color);
  font-size: 2rem;
}

.welcome-title p {
  color: var(--blue-fonts-color);
}

.add-project-input { 
  display: flex;
  align-items: center;
  width: 100%;
}

.add-project-input .form-control {
  font-family: var(--third-font-family);
  background-color: transparent;
  color: var(--main-fonts-color);
  border: 2px solid var(--main-blue-border-shadow);
  border-radius: 3px; 
  flex: 1;
}

.add-project-input .form-control::placeholder {
  color: #6e778abb; 
}

.add-project-button, 
.toggle-completed-button { 
  background-color: #1f9398; 
  color: #fff; 
  border: none;
  border-radius: 3px; 
  transition: background-color 0.2s ease; 
  margin-left: 10px; 
  white-space: nowrap;
}

.add-project-button:hover,
.toggle-completed-button:hover {
  background-color: #26b7be; 
}

.toggle-completed-button {
  background-color: #ff8c00;
  color: #b34444;
}

.toggle-completed-button:hover {
  background-color: #ffa000;
}

.toggle-completed-button.btn-green { /* Estilo para botão verde ativo */
  background-color: #4CAF50;
  color: white;
}

.col-12 {
  display: flex;
  overflow-y: auto;
}

.list-group {
  display: flex;
  border-radius: 0;
  width: 100%;
  gap: 10px;
}

.project-list { 
  display: flex;
  margin-top: 20px;
  height: 63vh; 
  overflow-y: auto;
}

li.project-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--third-font-family);
  color: var(--main-fonts-color);
  font-size: 1rem;
  height: 50px;
  text-decoration: none;
  border: none;
  border-bottom: 1px solid var(--main-fonts-color);
  background: none; /* Fundo transparente */
}

a{
  text-decoration: none;
  
}

.project-name {
  color: #3e3e3e;
  text-decoration: none;
  font-family: var(--third-font-family);
  font-size: 1rem;
}



.project-actions { 
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: row; /* Botões em linha */
  gap: 5px; /* Espaçamento entre os botões */
}

.project-item:hover .project-actions {
  opacity: 1; 
}

.btn-action { 
  padding: 6px;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; 
  background-color: transparent;
  cursor: pointer;
  color: #3e3e3e; 
}

.btn-action.btn-success {
  border-color: #4CAF50;
}

.btn-action.btn-warning {
  border-color: #FFC107; 
}

.btn-action.btn-danger {
  border-color: #f44336; 
}

.edit-project-form {  /* Estilos para o formulário de edição */
  font-family: var(--third-font-family);
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit-project-form input { /* Estilos para o input de edição */
  padding: 5px;
  border: 1px solid #ced4da; /* Cor da borda do Bootstrap */
  border-radius: 4px; /* Cantos arredondados do Bootstrap */
}

.edit-project-form button { /* Estilos para os botões de edição */
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
}

.edit-project-form button:first-child { /* Botão "Salvar" */
  background-color: #28a745; /* Cor verde do Bootstrap */
  color: white;
}

.edit-project-form button:last-child { /* Botão "Cancelar" */
  background-color: #dc3545; /* Cor vermelha do Bootstrap */
  color: white;
}

/* Media Queries */
@media (max-width: 968px) {
  .home-container { 
    height: 90vh;
    padding-top: 0px; 
  }

  .project-header {
    margin: 0;
    padding: 20px; 
    width: 100%;
    text-align: center; 
  }

  .btn-group-header {
    width: 100%; 
    justify-content: center;
    margin-top: 10px;
  }

  .add-project-input {
    padding: 0;
    width: 100%;
    flex-direction: column; 
    align-items: center; 
    margin-bottom: 10px;
  }

  .project-list {
    padding: 0;
    width: 100%; 
    height: auto;
  }

  .project-item {
    text-align: left;
    width: 100%;
    align-items: center; 
    height: auto;
    padding: 20px;
    gap: 10px;
  }
}